<template>
  <Stack
    tag="ul"
    gap="2xs"
    role="list"
    direction="row"
    justify="center"
    align="start"
    :breakpoints="{ direction: { md: 'row' } }"
    class="list-none"
    data-test-id="pagination"
  >
    <li>
      <PaginationLink
        :link="pageLink(current - 1)"
        :state="current === 1 ? 'disabled' : 'idle'"
        @click="
          navigate(Direction.BACK);
          handleUpdatePage(current);
        "
      >
        <Icon name="chevron-left" :size="14" />
        <p class="sr-only">Previous Page</p>
      </PaginationLink>
    </li>

    <li v-for="paginationLink in generateLinks" :key="paginationLink" v-if="generateLinks.length > 0" role="listitem">
      <PaginationLink
        data-test-id="paginationButton"
        :link="pageLink(paginationLink)"
        :state="state(paginationLink)"
        @click="
          navigate(paginationLink);
          handleUpdatePage(paginationLink);
        "
      >
        {{ paginationLink === Direction.NEUTRAL ? "..." : paginationLink }}
      </PaginationLink>
    </li>

    <li>
      <PaginationLink
        :link="pageLink(current + 1)"
        :state="current === pages ? 'disabled' : 'idle'"
        @click="
          navigate(Direction.NEXT);
          handleUpdatePage(current);
        "
      >
        <Icon name="chevron-right" :size="14" />
        <p class="sr-only">Next Page</p>
      </PaginationLink>
    </li>
  </Stack>
</template>

<script setup lang="ts">
import type { PaginationProps } from "./types";
import PaginationLink from "./PaginationLink.vue";
import { ref, watch } from "vue";
import { Direction } from "./DirectionEnum";

const props = withDefaults(defineProps<PaginationProps>(), {
  items: 1,
  page: 1,
  perPage: 10,
  buttons: 3,
});

const emit = defineEmits<{
  (event: "update:page", value: number): void;
}>();

const handleUpdatePage = (page: number) => {
  emit("update:page", page);
};

const current = ref(props.page);
const pages = ref(Math.ceil(props.items));

const showLast = computed(() => {
  return pages.value > props.buttons && current.value < pages.value - 1;
});

const showFirst = computed(() => {
  return pages.value > props.buttons + 1 && current.value > 2;
});

const pageLink = (page: number) => {
  return `?page=${page}&limit=${props.perPage}`;
};

const state = (page: number) => {
  if (page === Direction.NEUTRAL) {
    return "disabled";
  }

  return current.value === page ? "active" : "idle";
};

const navigate = (direction: Direction) => {
  switch (direction) {
    case Direction.NEUTRAL:
      return;
    case Direction.BACK:
      current.value !== 1 && current.value--;

      return;
    case Direction.NEXT:
      current.value !== pages.value && current.value++;

      return;
    default:
      current.value = direction;
  }
};

const generateLinks = computed(() => {
  let links = [];
  const start = Math.max(1, current.value - 1);

  for (let i = start; i <= start + props.buttons - 1; i++) {
    if (i > pages.value) {
      break;
    }

    i === pages.value - 1 && links.length === 0 && i - 1 && links.push(i - 1);

    links.push(i);
  }

  links = showFirst.value ? [1, Direction.NEUTRAL, ...links] : links;
  links = showLast.value ? [...links, Direction.NEUTRAL, pages.value] : links;

  return links;
});

watch(
  () => props.page,
  (newPage) => {
    current.value = newPage;
  },
);

watch(
  () => props.items,
  (newItems) => {
    pages.value = Math.ceil(newItems);
  },
);
</script>
